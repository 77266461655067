import {
  AspectRatio,
  Box,
  Button,
  CloseButton,
  Heading,
  HStack,
  Image,
  Input,
  Text,
  VStack,
} from "@efishery/onefish";
import { useState } from "react";
import { PiFacebookLogo, PiWhatsappLogo } from "react-icons/pi";
import { FacebookShareButton, WhatsappShareButton } from "react-share";
import DefaultPromoIMG from "src/assets/images/promo_v2_default_img.jpg";
import { BottomSheet } from "src/components";
import { ENV } from "src/configs/env";
import { AQUA, BLACK, OBSERVATORY } from "src/constants/theme";
import {
  trackClickShareReferral,
  trackClickShareReferralChannel,
} from "src/features/Referral/pages/Referral/tracker";

import { shareContent, shareURLFacebook } from "../../constant";
import { IReferral, ReferralShareChannelEnum } from "../../types";

interface SharedReferralProps {
  code: IReferral["code"];
  authToken: string;
  shareBanner?: string;
  shareBannerAlt: string;
}

export const SharedReferral = ({
  code,
  authToken,
  shareBanner,
  shareBannerAlt,
}: SharedReferralProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleBagikanClick = () => {
    trackClickShareReferral(authToken);
    openModal();
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const BottomSheetContent = () => (
    <>
      <HStack
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        fontSize={["1.15rem", "1.25rem"]}
        fontWeight="600"
        pb="16px"
        width="full"
      >
        <Text>Bagikan Kode Rekomendasi</Text>
        <CloseButton
          color={OBSERVATORY[500]}
          size={["2xl", "4xl"]}
          onClick={closeModal}
        />
      </HStack>
      <VStack width="full">
        <VStack width="full" gap="1rem">
          <AspectRatio ratio={1 / 1} width="full">
            <Image
              fallbackSrc={`${ENV.APP_HOST}${DefaultPromoIMG}`}
              src={shareBanner}
              alt={shareBannerAlt}
              width="full"
              objectFit="cover"
              data-testid="img_promoku-detail-header-image"
            />
          </AspectRatio>
          <Input
            fontSize="2xl"
            size="lg"
            bg={BLACK[50]}
            opacity="1 !important"
            cursor="auto !important"
            color={BLACK[400]}
            fontWeight="600"
            textAlign="center"
            value={code?.toUpperCase()}
            variant="filled"
            data-testid="txtfld_referral_code"
            disabled
          />
          <Text size="0.875rem" fontWeight="400" color={BLACK[400]}>
            Bagikan lewat WhatsApp/sosmed lainnya dengan menekan logo di bawah.
          </Text>
          <HStack gap="3" width="100%">
            <WhatsappShareButton
              style={{
                width: "100%",
              }}
              url={shareContent(code ?? "")}
            >
              <Button
                shadow="0"
                backgroundColor={BLACK[50]}
                borderRadius="0.5rem"
                w="100%"
                color="black"
                py="10px"
                onClick={() => {
                  trackClickShareReferralChannel(
                    authToken,
                    ReferralShareChannelEnum.Whatsapp
                  );
                }}
                _hover={{
                  color: "white",
                  backgroundColor: "brand.600",
                }}
                leftIcon={<PiWhatsappLogo size="32" />}
              >
                <Text fontWeight="400">Whatsapp</Text>
              </Button>
            </WhatsappShareButton>
            <FacebookShareButton
              style={{
                width: "100%",
              }}
              url={shareURLFacebook(code ?? "")}
            >
              <Button
                shadow="0"
                backgroundColor={BLACK[50]}
                borderRadius="0.5rem"
                w="100%"
                color="black"
                py="10px"
                onClick={() => {
                  trackClickShareReferralChannel(
                    authToken,
                    ReferralShareChannelEnum.Facebook
                  );
                }}
                _hover={{
                  color: "white",
                  backgroundColor: "brand.600",
                }}
                leftIcon={<PiFacebookLogo size="32" />}
              >
                <Text fontWeight="400">Facebook</Text>
              </Button>
            </FacebookShareButton>
          </HStack>
        </VStack>
      </VStack>
    </>
  );

  return (
    <>
      <VStack
        alignSelf="center"
        borderRadius="10px"
        maxWidth="full"
        marginTop="-10%"
        zIndex={1}
      >
        <VStack
          spacing="1rem"
          width="full"
          border="solid"
          borderColor={BLACK[100]}
          borderWidth="1px"
          padding="1rem"
          backgroundColor="white"
          borderRadius="8px"
          height="auto"
          boxShadow="0px 2px 8px rgba(0, 0, 0, 0.08)"
        >
          <HStack width="full" alignItems="space-between" spacing="0.5rem">
            <Box
              width="full"
              backgroundColor={AQUA[100]}
              borderRadius="8px"
              alignContent="center"
              textAlign="center"
            >
              <Heading size="16px" color={BLACK[400]} fontWeight="600">
                {code?.toUpperCase()}
              </Heading>
            </Box>
            <Button
              size="md"
              fontWeight="500"
              variant="solid"
              boxShadow=""
              data-testid="btn_referral_share"
              paddingX="24px"
              _hover={{
                boxShadow: "",
              }}
              onClick={handleBagikanClick}
            >
              Bagikan
            </Button>
          </HStack>
          <Text size="0.875rem" fontWeight="400" color={BLACK[400]}>
            Bagikan kode ini ke petani lain. Makin banyak yang gabung, makin
            banyak untung!
          </Text>
        </VStack>
      </VStack>
      <BottomSheet
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        component={BottomSheetContent}
      />
    </>
  );
};
